import { LocalService } from './../localStorage/services/local.service';

export class MenuConfig {
	public moduleData: any;
	public subModuleData: Array<any> = [];
	public roleId: Array<any>;
	public defaults: any;
	public roleIdOfUser: number;
	moduleList: any;
	permissions: any;
	actionData: any;
	constructor(
		private localService: LocalService,
	) {
		// this.moduleData = this.localService.getModuleList();
		this.moduleList = JSON.parse(localStorage.getItem("moduleList"));
		this.moduleData = this.moduleList.map(data => data.module_name);
		this.permissions = JSON.parse(localStorage.getItem("permissions"));
	    this.subModuleData = this.permissions.map(data => data.sub_module_name);
	    this.actionData = this.permissions.map(data => data.action);
		this.roleIdOfUser = parseInt(localStorage.getItem('roleId'));
		// this.subModuleData = this.localService.getSubModulePermission();
		// this.roleId = this.localService.getRoleId();

		this.defaults = {

			header: {
				self: {},
				items: [
					// {
					// 	title: 'Dashboard',
					// 	root: true,
					// 	icon: 'flaticon2-architecture-and-city',
					// 	page: '/dashboard',
					// 	translate: 'MENU.DASHBOARD',
					// 	bullet: 'dot',
					// },
					{
						title: 'Transactions',
						root: true,
						bullet: 'dot',
						icon: 'flaticon2-browser-2',
						submenu: [
							{
								title: 'App Creation Form',
								bullet: 'dot',
								page: '/transactions/add-app',
								icon: 'flaticon-web',
								permission: !this.subModuleData.includes('app creation form')
							},
							{
								title: 'All Requests',
								bullet: 'dot',
								page: '/transactions/admin-requests',
								icon: 'flaticon-web',
								permission: !this.subModuleData.includes('all requests')
							},
							{
								title: 'Awaiting Approval',
								bullet: 'dot',
								page: '/transactions/approvals',
								icon: 'flaticon-web',
								permission: !this.subModuleData.includes('awaiting approval')
							},
							{
								title: 'My Requests',
								bullet: 'dot',
								page: '/transactions/my-requests',
								icon: 'flaticon-web',
								permission: !this.subModuleData.includes('my requests')
							},
							{
								title: 'APP Upload',
								bullet: 'dot',
								page: '/transactions/app-upload',
								icon: 'flaticon-web',
								permission: !this.subModuleData.includes('app upload')
							},
							{
								title: 'APP Report',
								bullet: 'dot',
								page: '/transactions/app-report',
								icon: 'flaticon-web',
								permission: !this.subModuleData.includes('app report')
							},
							{
								title: 'APP Supplementary',
								bullet: 'dot',
								page: '/transactions/app-supplementary',
								icon: 'flaticon-web',
								permission: !this.subModuleData.includes('app supplementary')
							}]
						,
						permission: !this.moduleData.includes('transaction')

					},
					{
						title: 'Masters',
						root: true,
						bullet: 'dot',
						icon: 'flaticon2-browser-2',
						submenu: [

							{
								title: 'SAPGL Master',
								root: true,
								bullet: 'dot',
								icon: 'flaticon2-browser-2																																																																																																																																																																																																																																																																																																																																																																																																																						',
								permission: !this.subModuleData.includes('sapgl master'),
								submenu: [
									{
										title: 'Expenditure',
										bullet: 'dot',
										page: '/masters/expenditure',
										icon: 'flaticon-user',																																																																																																																																																																																																																																																																																																																																																																																																																						
										permission: !this.actionData.includes('expenditure'),
									},
									{
										title: 'Sub-Expenditure',
										bullet: 'dot',
										page: '/masters/sub-expenditure',
										icon: 'flaticon-user',																																																																																																																																																																																																																																																																																																																																																																																																																						
										permission: !this.actionData.includes('sub expenditure'),
									},
									{
										title: 'Sub-Sub-Expenditure',
										bullet: 'dot',
										page: '/masters/sub-sub-expenditure',
										icon: 'flaticon-user',																																																																																																																																																																																																																																																																																																																																																																																																																						
										permission: !this.actionData.includes('sub sub expenditure'),
									},
									{
										title: 'SAP GL Master',
										bullet: 'dot',
										page: '/masters/sapgl',
										icon: 'flaticon-user',																																																																																																																																																																																																																																																																																																																																																																																																																						
										permission: !this.actionData.includes('sapgl code'),
									},
								]

							},
							{
								title: 'Product Master',
								root: true,
								bullet: 'dot',
								icon: 'flaticon2-browser-2																																																																																																																																																																																																																																																																																																																																																																																																																						',
								permission: !this.subModuleData.includes('product master'),
								submenu: [
									{
										title: 'Products Upload',
										bullet: 'dot',
										page: '/masters/products-upload',
										icon: 'flaticon-user',																																																																																																																																																																																																																																																																																																																																																																																																																						
										permission: !this.actionData.includes('products upload'),
									},
									{
										title: 'Product List',
										bullet: 'dot',
										page: '/masters/product-list',
										icon: 'flaticon-user',																																																																																																																																																																																																																																																																																																																																																																																																																						
										permission: !this.actionData.includes('product list'),
									},
									{
										title: 'Product Report',
										bullet: 'dot',
										page: '/masters/product-report',
										icon: 'flaticon-user',																																																																																																																																																																																																																																																																																																																																																																																																																						
										permission: !this.actionData.includes('product report'),
									},
									{
										title: 'External Material',
										bullet: 'dot',
										page: '/masters/external-material',
										icon: 'flaticon-user',																																																																																																																																																																																																																																																																																																																																																																																																																						
										// permission: !this.actionData.includes('product report'),
									}
								]
							},
							{
								title: 'Workflow Master',
								root: true,
								bullet: 'dot',
								icon: 'flaticon2-browser-2																																																																																																																																																																																																																																																																																																																																																																																																																						',
								permission: !this.subModuleData.includes('workflow master'),
								submenu: [
									{
										title: 'User Based Workflow',
										bullet: 'dot',
										page: '/masters/user-based-workflow',
										icon: 'flaticon-user',																																																																																																																																																																																																																																																																																																																																																																																																																						
										permission: !this.actionData.includes('create workflow'),
									},
									{
										title: 'RM Based Workflow',
										bullet: 'dot',
										page: '/masters/workflow-2',
										icon: 'flaticon-user',																																																																																																																																																																																																																																																																																																																																																																																																																						
										permission: !this.actionData.includes('create workflow'),
									},
									{
										title: 'Exp/Brand Workflow',
										bullet: 'dot',
										page: '/masters/workflow',
										icon: 'flaticon-user',																																																																																																																																																																																																																																																																																																																																																																																																																						
										permission: !this.actionData.includes('create workflow'),
									},
									// {
									// 	title: 'Approvers',
									// 	bullet: 'dot',
									// 	page: '/masters/approvers',
									// 	icon: 'flaticon-user',																																																																																																																																																																																																																																																																																																																																																																																																																						
									// 	permission: !this.actionData.includes('approvers'),
									// }
								]																								
							},
							{
								title: 'Role Master',
								root: true,
								bullet: 'dot',
								icon: 'flaticon2-browser-2																																																																																																																																																																																																																																																																																																																																																																																																																						',
								// permission: !this.subModuleData.includes('role master'),
								submenu: [
									{
										title: 'Role',
										bullet: 'dot',
										page: '/masters/role',
										icon: 'flaticon-user',																																																																																																																																																																																																																																																																																																																																																																																																																						
										// permission: !this.actionData.includes('create roles'),
									}
								]
							},
							{
								title: 'User Master',
								root: true,
								bullet: 'dot',
								icon: 'flaticon2-browser-2																																																																																																																																																																																																																																																																																																																																																																																																																						',
								// permission: !this.subModuleData.includes('user master'),
								submenu: [
									{
										title: 'User',
										bullet: 'dot',
										page: '/masters/user',
										icon: 'flaticon-user',																																																																																																																																																																																																																																																																																																																																																																																																																						
										// permission: !this.actionData.includes('user master'),
									}
								]
							},
							{
								title: 'Budget Master',
								root: true,
								bullet: 'dot',
								icon: 'flaticon2-browser-2																																																																																																																																																																																																																																																																																																																																																																																																																						',
								// permission: !this.subModuleData.includes('budget master'),
								submenu: [
									{
										title: 'Budget with Sub Expenditure',
										bullet: 'dot',
										page: '/masters/budget-master',
										icon: 'flaticon-budget',																																																																																																																																																																																																																																																																																																																																																																																																																						
										// permission: !this.actionData.includes('budget master'),
									},
									{
										title: 'Budget with Sapgl Code',
										bullet: 'dot',
										page: '/masters/budget-master-sapgl',
										icon: 'flaticon-budget',																																																																																																																																																																																																																																																																																																																																																																																																																						
										// permission: !this.actionData.includes('budget master'),
									}
								]
							},
							{
								title: 'Secretary Master',
								root: true,
								bullet: 'dot',
								icon: 'flaticon2-browser-2																																																																																																																																																																																																																																																																																																																																																																																																																						',
								// permission: !this.subModuleData.includes('role master'),
								submenu: [
									{
										title: 'Secretary',
										bullet: 'dot',
										page: '/masters/secretary-master',
										icon: 'flaticon-user',																																																																																																																																																																																																																																																																																																																																																																																																																						
										// permission: !this.actionData.includes('create roles'),
									}
								]
							},
							{
								title: 'Other Masters',
								root: true,
								bullet: 'dot',
								icon: 'flaticon2-browser-2																																																																																																																																																																																																																																																																																																																																																																																																																						',
								permission: !this.subModuleData.includes('other masters'),
								submenu: [
									{
										title: 'Country',
										bullet: 'dot',
										page: '/masters/country',
										icon: 'flaticon-user',																																																																																																																																																																																																																																																																																																																																																																																																																						
										permission: !this.actionData.includes('country master'),

									},
									{
										title: 'Company',
										bullet: 'dot',
										page: '/masters/company',
										icon: 'flaticon-user',																																																																																																																																																																																																																																																																																																																																																																																																																						
										permission: !this.actionData.includes('company master'),

									},
									{
										title: 'Location',
										bullet: 'dot',
										page: '/masters/location',
										icon: 'flaticon-user',																																																																																																																																																																																																																																																																																																																																																																																																																						
										permission: !this.actionData.includes('location master'),

									},
									{
										title: 'Department',
										bullet: 'dot',
										page: '/masters/department',
										icon: 'flaticon-user',																																																																																																																																																																																																																																																																																																																																																																																																																						
										permission: !this.actionData.includes('department master'),

									},
									{
										title: 'Vendor',
										bullet: 'dot',
										page: '/masters/vendor',
										icon: 'flaticon-user',																																																																																																																																																																																																																																																																																																																																																																																																																						
										permission: !this.actionData.includes('vendor master'),

									},
									{
										title: 'Customer',
										bullet: 'dot',
										page: '/masters/customer',
										icon: 'flaticon-user',																																																																																																																																																																																																																																																																																																																																																																																																																						
										permission: !this.actionData.includes('customer master'),

									},
									{
										title: 'Region',
										bullet: 'dot',
										page: '/masters/region',
										icon: 'flaticon-user',																																																																																																																																																																																																																																																																																																																																																																																																																						
										permission: !this.subModuleData.includes('region master'),

									},
								]
							},
																																																																																																																																																																																																																																																																																																																																																																																																																												],
						permission: !this.moduleData.includes('masters')
					},
					{
						title: 'Reports',
						root: true,
						bullet: 'dot',
						icon: 'flaticon2-browser-2',
						submenu: [
							// {
							// 	title: 'View Task History',
							// 	page: '/reports/task-history',
							// 	bullet: 'dot',
							// 	icon: 'flaticon-user',
							// },
							{
								title: 'APP Finance Report',
								page: '/reports/finance-history',
								bullet: 'dot',
								icon: 'flaticon-user',
								permission: !this.actionData.includes('finance history'),
							},
							// {
							// 	title: 'View Masters Details',
							// 	page: '/reports/master-details',
							// 	bullet: 'dot',
							// 	icon: 'flaticon-user',
							// },
							// {
							// 	title: 'APP IO Report',
							// 	page: '/reports/io-report',
							// 	bullet: 'dot',
							// 	icon: 'flaticon-user',
							// },
						]
						,
						permission: !this.moduleData.includes('reports')
					},
					{
						title: 'Global Login',
						root: true,
						bullet: 'dot',
						icon: 'flaticon2-browser-2',
						page: '/login/global-login',
						// submenu: [
						// 	// {
						// 	// 	title: 'View Task History',
						// 	// 	page: '/reports/task-history',
						// 	// 	bullet: 'dot',
						// 	// 	icon: 'flaticon-user',
						// 	// },
						// 	{
						// 		title: 'APP Finance Report',
						// 		page: '/reports/finance-history',
						// 		bullet: 'dot',
						// 		icon: 'flaticon-user',
						// 		permission: !this.actionData.includes('finance history'),
						// 	}
						// ]
						// ,
						permission: !this.subModuleData.includes('global login')
					},
					{
						title: 'Approvers Login',
						root: true,
						bullet: 'dot',
						icon: 'flaticon2-browser-2',
						page: '/login/secretary-login',
						// submenu: [
						// 	// {
						// 	// 	title: 'View Task History',
						// 	// 	page: '/reports/task-history',
						// 	// 	bullet: 'dot',
						// 	// 	icon: 'flaticon-user',
						// 	// },
						// 	{
						// 		title: 'APP Finance Report',
						// 		page: '/reports/finance-history',
						// 		bullet: 'dot',
						// 		icon: 'flaticon-user',
						// 		permission: !this.actionData.includes('finance history'),
						// 	}
						// ]
						// ,
						permission: !this.subModuleData.includes('secretary login')
					},
				]
			},
		};
	}
	public get configs(): any {
		return this.defaults;
	}
}
